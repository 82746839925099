import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { AppIconComponent } from './app-icon/app-icon.component';

@NgModule({
  declarations: [AppIconComponent],
  exports: [AppIconComponent],
  imports: [CommonModule, MatIconModule],
})
export class IconModule {}
