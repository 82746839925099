import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewEncapsulation,
  computed,
  input,
} from '@angular/core';
import { Origin8Icon, outlinedIcons, filledIcons, customIcon } from '../icon';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'o8-icon',
  template: `@if (icon() && !customIcon()) {
    <mat-icon
      [color]="color()"
      class="centered-vertical centered-horizontal"
      [class.material-icons-outlined]="outlined()"
      [class.material-icons-filled]="filled()"
      >{{ icon() }}</mat-icon
    >
    } @if (icon() && customIcon()) {
    <div
      class="o8-custom-icon centered-vertical centered-horizontal"
      [class.custom-icon-primary]="primaryTheme()"
      [class.custom-accent-primary]="accentTheme()"
      [class.custom-danger-primary]="dangerTheme()"
      [class.custom-warn-primary]="warnTheme()"
      [innerHtml]="data$ | async"
    ></div>
    }`,
  styleUrls: ['./app-icon.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppIconComponent implements OnInit {
  icon = input.required<Origin8Icon>();
  color = input<'primary' | 'accent' | 'warn' | 'danger' | ''>('');
  outlined = computed(() => outlinedIcons.some((i) => i === this.icon()));
  filled = computed(() => filledIcons.some((i) => i === this.icon()));
  primaryTheme = computed(() => this.color() === 'primary');
  accentTheme = computed(() => this.color() === 'accent');
  dangerTheme = computed(() => this.color() === 'danger');
  warnTheme = computed(() => this.color() === 'warn');
  customIcon = computed(() => customIcon.some((i) => i === this.icon()));
  data$!: Observable<SafeHtml>;

  constructor(
    private domSanitizer: DomSanitizer,
    private http: HttpClient,
    @Inject(PLATFORM_ID) private plateformId: NonNullable<unknown>,
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.plateformId) && this.customIcon()) {
      this.data$ = this.http.get(`/assets/common-svg/${this.icon()}.svg`, { responseType: 'text' }).pipe(
        map((html) => {
          return this.domSanitizer.bypassSecurityTrustHtml(html);
        }),
      );
    }
  }
}
