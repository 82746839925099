/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
export enum Origin8Icon {
  check = 'check_circle',
  selected = 'check',
  plus = 'add',
  minus = 'remove',
  caretDown = 'arrow_drop_down',
  caretUp = 'arrow_drop_up',
  call = 'call',
  goodHealth = 'favorite',
  lowHealth = 'favorite_border',
  tryAgain = 'replay',
  done = 'done',
  goBack = 'arrow_back_ios',
  goNext = 'arrow_forward_ios',
  plusCircle = 'add_circle',
  minusCircle = 'remove_circle',
  menu = 'menu',
  close = 'close',
  closeCircle = 'close-circle',
  notPresent = 'do_not_disturb',
  advocate = 'support_agent',
  messageWarning = 'sms_failed',
  play = 'play_arrow',
  pause = 'pause',
  volume = 'volume_up',
  volumeOff = 'volume_off',
  link = 'link',
  delete = 'delete',
  attention = 'attention',
  payment = 'credit_card',
  queueDetails = 'queue_play_next',
  hideQueueDetails = 'remove_from_queue',
  phone = 'phone',
  email = 'email',
  mapMarker = 'mapMarker',
  facebook = 'facebook',
  linkedin = 'linkedin',
  location = 'location_on',
  time = 'schedule',
  refresh = 'sync',
  warning = 'warning',
  restart = 'restart_alt',
  info = 'info',
  openFull = 'open_in_full',
  stop = 'stop_circle',
  person = 'person',
  employee = 'badge',
  externalLink = 'externalLink',
  windows = 'view_cozy',
  switch = 'sync_alt',
  remove = 'remove',
  notice = 'error',
  help = 'help',
  enterData = 'keyboard',
  collapsed = 'expand_less',
  expanded = 'expand_more',
  deleteTrashCan = 'delete-trash-can',
  systemUser = 'system-user',
  feedbackDown = 'feedback-down',
  feedbackUp = 'feedback-up',
  lowPriority = 'low-priority',
  mediumPriority = 'medium-priority',
  highPriority = 'high-priority',
  salesforce = 'salesforce',
  filter = 'filter_alt',
  filterOff = 'filter_alt_off',
  duration = 'hourglass_empty',
  dateTime = 'event',
  editNote = 'edit-note',
  deleteNote = 'delete-note',
  notes = 'notes',
  userActions = 'more_vert',
  snooze = 'snooze',
  upload = 'upload',
  download = 'download',
  search = 'search',
  autoAssign = 'auto-assign',
  openNew = 'open_in_new',
  onlineAdvocate = 'online-advocate',
  collapsePanel = 'keyboard_double_arrow_left',
  expandPanel = 'keyboard_double_arrow_right',
  suggestion = 'suggestion',
}

/* Value must match the name of the svg for automatic match*/
export const customIcon = [
  Origin8Icon.email,
  Origin8Icon.mapMarker,
  Origin8Icon.phone,
  Origin8Icon.facebook,
  Origin8Icon.linkedin,
  Origin8Icon.warning,
  Origin8Icon.person,
  Origin8Icon.externalLink,
  Origin8Icon.deleteTrashCan,
  Origin8Icon.systemUser,
  Origin8Icon.feedbackDown,
  Origin8Icon.feedbackUp,
  Origin8Icon.lowPriority,
  Origin8Icon.mediumPriority,
  Origin8Icon.highPriority,
  Origin8Icon.salesforce,
  Origin8Icon.notes,
  Origin8Icon.editNote,
  Origin8Icon.deleteNote,
  Origin8Icon.upload,
  Origin8Icon.download,
  Origin8Icon.autoAssign,
  Origin8Icon.attention,
  Origin8Icon.closeCircle,
  Origin8Icon.onlineAdvocate,
  Origin8Icon.suggestion,
];
export const outlinedIcons = [
  Origin8Icon.location,
  Origin8Icon.filter,
  Origin8Icon.employee,
  Origin8Icon.filterOff,
  Origin8Icon.expandPanel,
  Origin8Icon.collapsePanel,
];
export const filledIcons = [
  Origin8Icon.check,
  Origin8Icon.caretDown,
  Origin8Icon.caretUp,
  Origin8Icon.minus,
  Origin8Icon.plus,
  Origin8Icon.call,
  Origin8Icon.goodHealth,
  Origin8Icon.lowHealth,
  Origin8Icon.tryAgain,
  Origin8Icon.done,
  Origin8Icon.goNext,
  Origin8Icon.goBack,
  Origin8Icon.plusCircle,
  Origin8Icon.minusCircle,
  Origin8Icon.menu,
  Origin8Icon.close,
  Origin8Icon.notPresent,
  Origin8Icon.advocate,
  Origin8Icon.messageWarning,
  Origin8Icon.play,
  Origin8Icon.volume,
  Origin8Icon.volumeOff,
  Origin8Icon.link,
  Origin8Icon.delete,
  Origin8Icon.payment,
  Origin8Icon.queueDetails,
  Origin8Icon.hideQueueDetails,
  Origin8Icon.refresh,
  Origin8Icon.time,
  Origin8Icon.restart,
  Origin8Icon.openFull,
  Origin8Icon.stop,
  Origin8Icon.windows,
  Origin8Icon.switch,
  Origin8Icon.remove,
  Origin8Icon.notice,
  Origin8Icon.help,
  Origin8Icon.enterData,
  Origin8Icon.collapsed,
  Origin8Icon.expanded,
  Origin8Icon.filter,
  Origin8Icon.goBack,
  Origin8Icon.duration,
  Origin8Icon.dateTime,
  Origin8Icon.userActions,
  Origin8Icon.snooze,
  Origin8Icon.search,
  Origin8Icon.selected,
];
